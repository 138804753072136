body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-family: 'Montserrat', 'sans-serif';
}

a {
  color: inherit;
  text-decoration: none;
}
